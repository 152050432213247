export default {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  gtmGoVirtualActive: process.env.NEXT_PUBLIC_GTM_GO_VIRTUAL_ACTIVE,
  sycExperimentId: process.env.NEXT_PUBLIC_SYC_EXPERIMENT_ID,
  srpExperimentId: process.env.NEXT_PUBLIC_SRP_EXPERIMENT_ID,
  srpFilterExperimentId: process.env.NEXT_PUBLIC_SRP_FILTER_EXPERIMENT_ID,
  srpMobileCardExperimentId: process.env.NEXT_PUBLIC_SRP_MOBILE_CARD_EXPERIMENT_ID,
  srpBannerExperimentId: process.env.NEXT_PUBLIC_SRP_BANNER_EXPERIMENT_ID,
  easyExperimentId: process.env.NEXT_PUBLIC_EASY_EXPERIMENT_ID,
  easyVTAExperiment: process.env.NEXT_PUBLIC_EASY_VTA_EXPERIMENT_ID,
  easyVtaFeatureFlag: process.env.NEXT_PUBLIC_EASY_VTA_FEATURE_FLAG,
  srpUrgencyExperimentId: process.env.NEXT_PUBLIC_URGENCY_EXPERIMENT_ID,
  personalInfoExperimentId: process.env.NEXT_PUBLIC_PERSONAL_INFO_EXPERIMENT_ID,
  vtaEasyOfferExperimentId: process.env.NEXT_PUBLIC_VTA_EASY_OFFER_EXPERIMENT_ID,
  vtaEasyOfferVariant: process.env.NEXT_PUBLIC_VTA_EASY_OFFER_VARIANT, // TODO: REMOVE
  referedPriceExperimentId: process.env.NEXT_PUBLIC_REFERED_PRICE_EXPERIMENT_ID, // TODO: REMOVE
  priceRatingUser: process.env.NEXT_PRICE_RATING_USER,
  priceRatingPassword: process.env.NEXT_PRICE_RATING_PASSWORD,
  sycFomoExperimentId: process.env.NEXT_PUBLIC_SRP_EXPERIMENT_ID,
  homeExperimentId: process.env.NEXT_PUBLIC_PRICE_HOME_EXPERIMENT_ID,
  informationFomoExperimentId: process.env.NEXT_PUBLIC_INFORMATION_FOMO_EXPERIMENT_ID,
  vtaLandingPageExperimentId: process.env.NEXT_PUBLIC_VTA_LANDING_EXPERIMENT_ID,
  wsApiRoot: process.env.NEXT_PUBLIC_WS_API_ROOT,
  userAccountApiPath: process.env.NEXT_PUBLIC_USER_ACCOUNT_BASE_URL,
  userAccountFrontPath: process.env.NEXT_PUBLIC_USER_ACCOUNT_FRONT_URL,
  vehiclesPerPage: 24,
  vehiclesAuctionsPerPage: 40,
}
