const currentYear = new Date().getFullYear()
const currentYearDigExp = new Date().getFullYear() // 2023

export const yearCount = 14 // TODO: return to 12
export const durationInMinutes = 90
export const defaultYearsEasy = Array(23)
  .fill(0)
  .map((_, i) => (currentYear - i - 1).toString()) // TODO: remove -i -1 to show current year

export const defaultYears = Array(yearCount)
  .fill(0)
  .map((_, i) => (currentYear - i).toString()) // TODO: remove -i -1 to show current year

export const defaultYearsDigExp = Array(25)
  .fill(0)
  .map((_, i) => (currentYearDigExp - i).toString()) // TODO: remove -i -1 to show current year

// TODO: Remove this harcoded data. This should be fetch at runtime or built time from a WP rest API
export const siteName = 'ClikAuto'
export const easySiteName = 'Easy by ClikAuto'
export const temporaryImageDomain = process.env.NEXT_PUBLIC_API_ROOT === 'https://api.clikauto.com'
  ? 'https://imgs.clikauto.com'
  : 'https://dj0fev86c5kks.cloudfront.net'

export const commentsPhone = '525593020533'
export const checkoutNoOfferPhone = '525593020533'

export const mainMenu = [
  {
    id: 1,
    url: '/',
    title: 'ClikAuto',
    alt: 'ClikAuto',
    showIcon: false,
    hiddenHeader: true,
    items: [
      // {
      //   id: 11,
      //   url: '/preguntas-frecuentes',
      //   title: 'Preguntas frecuentes',
      //   skipPrefetch: true,
      // },
      {
        id: 13,
        url: '/blog',
        title: 'Blog',
      },
      {
        id: 13,
        url: `//wa.me/${commentsPhone}?text=Hola, quiero compartir mis comentarios y sugerencias de la experiencia que viví en ClikAuto.`,
        title: 'Comentarios y Sugerencias',
        type: 'external',
      },
    ],
  },
  {
    id: 100,
    url: '/autos-seminuevos',
    title: 'Autos seminuevos',
    alt: 'Los mejores autos seminuevos en venta',
    showIcon: true,
    titleFooter: 'Compra un auto seminuevo en ClikAuto',
  },
  {
    id: 200,
    url: '/vende-tu-auto',
    title: 'Vende tu auto',
    alt: 'Vende tu auto aquí',
    showIcon: true,
  },
  // {
  //   id: 300,
  //   url: '/como-comprar',
  //   title: 'Como comprar',
  //   alt: 'Así compras un seminuevo',
  //   skipPrefetch: true,
  // },
  {
    id: 400,
    url: '/credito-autos-seminuevos',
    title: 'Crédito',
    alt: 'Crédito de autos seminuevos',
    showIcon: true,
  },
  {
    id: 700,
    url: '/suscripcion',
    title: 'Suscripción',
    alt: 'Suscripción de seminuevos',
    showIcon: true,
    hideInFooter: true,
    hideInHeader: true,
  },
  {
    id: 500,
    url: '/blog',
    title: 'Blog',
    alt: 'Blog ClikAuto',
    showIcon: true,
  },
  {
    id: 900,
    url: '/sucursales',
    title: 'Sucursales',
    alt: 'Sucursales ClikAuto',
    showIcon: true,
    hideInFooter: false,
  },
]

export const footerAuctionMenu = [
  {
    id: 201,
    url: '/terminos-subastas',
    title: 'Términos y condiciones',
    relNoOpener: false,
  },
  {
    id: 202,
    url: '/aviso-de-privacidad',
    title: 'Aviso legal',
    relNoOpener: false,
  },
]
export const footerMenu = [
  {
    id: 201,
    url: '/terminos-y-condiciones',
    title: 'Términos y condiciones',
    relNoOpener: false,
    showFooter: true,
  },
  {
    id: 202,
    url: '/aviso-de-privacidad',
    title: 'Aviso legal',
    relNoOpener: false,
    showFooter: false,
  },
]
export const footerEasyMenu = [
  {
    id: 201,
    url: '/aviso-de-privacidad',
    title: 'Aviso legal',
    relNoOpener: false,
  },
  {
    id: 202,
    url: '/terminos-y-condiciones',
    title: 'Términos y condiciones',
    relNoOpener: false,
  },
  {
    id: 203,
    url: '/easy',
    title: 'Política de privacidad y cookies',
    relNoOpener: false,
  },
]

export const appraisalFooter = [
  {
    id: 202,
    url: '/terminos-y-condiciones',
    title: 'Términos y condiciones',
    relNoOpener: false,
  },
]

export const easyLandingMenu = [
  {
    alt: 'Easy by Clikauto',
    id: 100,
    title: 'Inicio',
    url: '/easy',
  },
  {
    alt: '¿Por qué Easy?',
    id: 200,
    title: '¿Por qué Easy?',
    url: '/easy/#Porqueeasy',
  },
  {
    alt: 'Testimonios',
    id: 300,
    title: 'Testimonios',
    url: '/easy/#Testimonios',
  },
  {
    alt: '¿Cómo funciona?',
    id: 400,
    title: '¿Cómo funciona?',
    url: '/easy/#Comofunciona',
  },
  {
    alt: '¿Todos los autos?',
    id: 600,
    title: '¿Todos los autos?',
    url: '/easy/#Todoslosautos',
  },
  {
    alt: 'Preguntas frecuentes',
    id: 700,
    title: 'Preguntas frecuentes',
    url: '/easy/#Preguntasfrecuentes',
  },
]

export const easyAgenciasLandingMenu = [
  {
    alt: 'Easy by Clikauto',
    id: 100,
    title: 'Inicio',
    url: '/easy-agencias',
  },
  {
    alt: '¿Por qué Easy?',
    id: 200,
    title: '¿Por qué Easy?',
    url: '/easy-agencias/#Porqueeasy',
  },
  {
    alt: '¿Cómo funciona?',
    id: 400,
    title: '¿Cómo funciona?',
    url: '/easy-agencias/#Comofunciona',
  },
  {
    alt: 'Contacto',
    id: 500,
    title: 'Contacto',
    url: '/easy-agencias/#contacto',
  },
  {
    alt: 'Preguntas frecuentes',
    id: 700,
    title: 'Preguntas frecuentes',
    url: '/easy-agencias/#Preguntasfrecuentes',
  },
]

export const auctionMenu = [
  {
    alt: 'Subastas disponibles',
    id: 100,
    title: 'Subastas disponibles',
    url: '/subastas/autos',
  },
  {
    alt: 'Mis subastas',
    id: 200,
    title: 'Mis subastas',
    url: '/subastas/mis-subastas/status-proposed',
  },
  {
    alt: 'Autos ganados',
    id: 400,
    title: 'Autos ganados',
    url: '/subastas/autos-ganados',
  },
  {
    alt: 'Subastas Perdidas',
    id: 500,
    title: 'Subastas Perdidas',
    url: '/subastas/mis-subastas/status-declined',
  },
  {
    alt: 'Mi perfil',
    id: 600,
    title: 'Mi perfil',
    url: '/subastas/mi-perfil',
  },
]

export const countryCode = '52'
export const salesPhone = '5589505877'
export const vtaPhone = '8000302886'
export const vtaPhoneQro = '4424562516'
export const shoppingPhone = '5589571916'
export const homePhoneNumber = '5589938582'
export const address = 'Sucursal central: Perif. Blvd. Manuel Ávila Camacho 810, Las Margaritas, 54050 Tlalnepantla de Baz, Méx. Ver en mapa'
export const addressLink = 'https://goo.gl/maps/rStBeHkBoL1bhCEd6'
export const whatsapp = '5215541704169'
export const whatsappVTA = '5215541704164'
export const whatsappEasy = '5215541704169' // 5215593020533
export const whatsappQro = '525592746572'
export const whatsappEasyLanding = '5215593020749'
export const whatsappEasyAgencias = '5215514936117'
export const whatsQueretaro = '5215593020413'
export const phoneQueretaro = '4424564707'
export const phoneQueretaroVDP = '4424562516'
export const auctionPhone = '5215514936117'
export const whatsappWelcomeMessage = 'Hola, quiero hablar con un asesor ClikAuto'
export const whatsappEasyWelcomeMessage = 'Hola, me interesa vender mi auto con easy.'
export const whatsappWelcomeVDPMessage = 'Hola, quiero hablar con un asesor ClikAuto. Estoy viendo este'
export const whatsappSellYourCarMessage = 'Hola, quiero que me ayuden a vender mi auto, me gustaría hablar con un asesor ClikAuto'
export const whatsappCheckoutMessage = 'Hola, quiero hablar con un asesor ClikAuto'
export const gtmId = 'GTM-KZT487H'
export const hotjarId = '2217736'
export const fbPixelId = '820715348627948'
export const livechatLicence = 12595551
export const socialLinks = {
  facebook: 'https://www.facebook.com/ClikAuto',
  instagram: 'https://www.instagram.com/clikauto_/',
  linkedin: 'https://www.linkedin.com/company/clikauto/',
  twitter: 'https://twitter.com/clikauto_',
  youtube: 'https://www.youtube.com/channel/UC3gq6hT8fiLHSqbkTJgUlGg',
  tiktok: 'https://www.tiktok.com/@clikauto',
}

export const generalFaqs = [
  {
    id: 1,
    sectionTitle: 'FAQS_MAIN_TITLE',
    questions: [
      {
        question: 'FAQS_QUESTION_1_1',
        response: 'FAQS_ANSWER_1_1',
      },
      {
        question: 'FAQS_QUESTION_1_2',
        response: 'FAQS_ANSWER_1_2',
      },
      {
        question: 'FAQS_QUESTION_1_3',
        responseList: [
          'FAQS_ANSWER_1_3_1',
          'FAQS_ANSWER_1_3_2',
          'FAQS_ANSWER_1_3_3',
        ],
        listType: 'ordered',
      },
      {
        question: 'FAQS_QUESTION_1_4',
        response: 'FAQS_ANSWER_1_4',
      },
    ],
  },
  {
    id: 2,
    sectionTitle: 'Vende tu auto',
    questions: [
      {
        question: 'FAQS_QUESTION_2_1',
        responseList: [
          'FAQS_ANSWER_2_1_1',
          'FAQS_ANSWER_2_1_2',
          'FAQS_ANSWER_2_1_3',
        ],
        listType: 'ordered',
      },
      {
        question: 'FAQS_QUESTION_2_2',
        response: 'FAQS_ANSWER_2_2',
      },
      {
        question: 'FAQS_QUESTION_2_3',
        response: 'FAQS_ANSWER_2_3',
      },
      {
        question: 'FAQS_QUESTION_2_4',
        response: 'FAQS_ANSWER_2_4',
      },
      {
        question: 'FAQS_QUESTION_2_5',
        responseList: [
          'FAQS_ANSWER_2_5_1',
          'FAQS_ANSWER_2_5_2',
          'FAQS_ANSWER_2_5_3',
          'FAQS_ANSWER_2_5_4',
          'FAQS_ANSWER_2_5_5',
          'FAQS_ANSWER_2_5_6',
          'FAQS_ANSWER_2_5_7',
          'FAQS_ANSWER_2_5_8',
          'FAQS_ANSWER_2_5_9',
          'FAQS_ANSWER_2_5_10',
          'FAQS_ANSWER_2_5_11',
        ],
      },
      {
        question: 'FAQS_QUESTION_2_6',
        responseList: [
          'FAQS_ANSWER_2_6_1',
          'FAQS_ANSWER_2_6_2',
          'FAQS_ANSWER_2_6_3',
          'FAQS_ANSWER_2_6_4',
          'FAQS_ANSWER_2_6_5',
          'FAQS_ANSWER_2_6_6',
          'FAQS_ANSWER_2_6_7',
          'FAQS_ANSWER_2_6_8',
          'FAQS_ANSWER_2_6_9',
          'FAQS_ANSWER_2_6_10',
          'FAQS_ANSWER_2_6_11',
          'FAQS_ANSWER_2_6_12',
        ],
      },
      {
        question: 'FAQS_QUESTION_2_7',
        responseList: [
          'FAQS_ANSWER_2_7_1',
          'FAQS_ANSWER_2_7_2',
          'FAQS_ANSWER_2_7_3',
          'FAQS_ANSWER_2_7_4',
          'FAQS_ANSWER_2_7_5',
          'FAQS_ANSWER_2_7_6',
          'FAQS_ANSWER_2_7_7',
          'FAQS_ANSWER_2_7_8',
          'FAQS_ANSWER_2_7_9',
          'FAQS_ANSWER_2_7_10',
          'FAQS_ANSWER_2_7_11',
          'FAQS_ANSWER_2_7_12',
          'FAQS_ANSWER_2_7_13',
          'FAQS_ANSWER_2_7_14',
          'FAQS_ANSWER_2_7_15',
          'FAQS_ANSWER_2_7_16',
        ],
      },
      {
        question: 'FAQS_QUESTION_2_8',
        response: 'FAQS_ANSWER_2_8',
      },
      {
        question: 'FAQS_QUESTION_2_9',
        response: 'FAQS_ANSWER_2_9',
      },
      {
        question: 'FAQS_QUESTION_2_10',
        response: 'FAQS_ANSWER_2_10',
      },
    ],
  },
  {
    id: 3,
    sectionTitle: 'Crédito',
    questions: [
      {
        question: 'FAQS_QUESTION_3_1',
        response: 'FAQS_ANSWER_3_1',
      },
      {
        question: 'FAQS_QUESTION_3_2',
        response: 'FAQS_ANSWER_3_2',
      },
      {
        question: 'FAQS_QUESTION_3_3',
        response: 'FAQS_ANSWER_3_3',
      },
      {
        question: 'FAQS_QUESTION_3_4',
        response: 'FAQS_ANSWER_3_4',
      },
      {
        question: 'FAQS_QUESTION_3_5',
        responseList: [
          'FAQS_ANSWER_3_5_1',
          'FAQS_ANSWER_3_5_2',
          'FAQS_ANSWER_3_5_3',
          'FAQS_ANSWER_3_5_4',
        ],
        subsections: [
          {
            title: '',
            questions: [
              {
                question: 'FAQS_SUBTITLE_1',
                responseList: [
                  'FAQS_SUB_1_1',
                  'FAQS_SUB_1_2',
                  'FAQS_SUB_1_3',
                  'FAQS_SUB_1_4',
                ],
              },
              {
                question: 'FAQS_SUBTITLE_2',
                responseList: [
                  'FAQS_SUB_2_1',
                  'FAQS_SUB_2_2',
                  'FAQS_SUB_2_3',
                  'FAQS_SUB_2_4',
                ],
              },
            ],
          },
        ],
        note: '*Es muy importante que tengas a la mano esta documentación cuando solicites tu crédito.',
      },
      {
        question: 'FAQS_QUESTION_3_6',
        response: 'FAQS_ANSWER_3_6',
      },
    ],
  },
  {
    id: 4,
    sectionTitle: 'Suscripción',
    questions: [
      {
        question: 'FAQS_QUESTION_4_1',
        response: 'FAQS_ANSWER_4_1',
      },
      {
        question: 'FAQS_QUESTION_4_2',
        response: 'FAQS_ANSWER_4_2',
      },
      {
        question: 'FAQS_QUESTION_4_3',
        response: 'FAQS_ANSWER_4_3',
      },
      {
        question: 'FAQS_QUESTION_4_4',
        response: 'FAQS_ANSWER_4_4',
      },
      {
        question: 'FAQS_QUESTION_4_5',
        response: 'FAQS_ANSWER_4_5',
      },
      {
        question: 'FAQS_QUESTION_4_6',
        response: 'FAQS_ANSWER_4_6',
      },
    ],
  },
  {
    id: 5,
    sectionTitle: 'Embajadores',
    questions: [
      {
        question: 'FAQS_QUESTION_5_1',
        response: 'FAQS_ANSWER_5_1',
      },
      {
        question: 'FAQS_QUESTION_5_2',
        responseList: [
          'FAQS_ANSWER_5_2_1',
          'FAQS_ANSWER_5_2_2',
          'FAQS_ANSWER_5_2_3',
          'FAQS_ANSWER_5_2_4',
          'FAQS_ANSWER_5_2_5',
        ],
      },
      {
        question: 'FAQS_QUESTION_5_3',
        response: 'FAQS_ANSWER_5_3',
      },
      {
        question: 'FAQS_QUESTION_5_4',
        responseList: [
          'FAQS_ANSWER_5_4_1',
          'FAQS_ANSWER_5_4_2',
          'FAQS_ANSWER_5_4_3',
        ],
        note: 'FAQS_ANSWER_5_4_4',
      },
      {
        question: 'FAQS_QUESTION_5_5',
        response: 'FAQS_ANSWER_5_5',
      },
      {
        question: 'FAQS_QUESTION_5_6',
        response: 'FAQS_ANSWER_5_6',
      },
    ],
  },
]

export const homeFaqs = [
  {
    id: 1,
    question: 'HOME_QUESTION_2',
    response: 'HOME_ANSWER_2',
  },
  {
    id: 4,
    question: 'HOME_QUESTION_6',
    response: 'HOME_ANSWER_6',
  },
  {
    id: 2,
    question: 'HOME_QUESTION_4',
    response: 'HOME_ANSWER_4',
    button: {
      id: 1,
      text: 'Ver créditos',
      href: '/credito-autos-seminuevos',
      variant: 'link',
    },
  },
  {
    id: 5,
    question: 'HOME_QUESTION_1',
    response: 'HOME_ANSWER_1',
    minutesValue: durationInMinutes,
  },
]

export const easyFaqs = [
  {
    id: 1,
    question: 'EASY_QUESTION_1',
    response: 'EASY_ANSWER_1',
    minutesValue: 'EASY_ANSWER_1_P2',
  },
  {
    id: 2,
    question: 'EASY_QUESTION_2',
    response: 'EASY_ANSWER_2',
  },
  {
    id: 3,
    question: 'EASY_QUESTION_3',
    response: 'EASY_ANSWER_3',
  },
  {
    id: 4,
    question: 'EASY_QUESTION_4',
    response: 'EASY_ANSWER_4',
  },
  {
    id: 5,
    question: 'EASY_QUESTION_5',
    response: 'EASY_ANSWER_5',
  },
  {
    id: 6,
    question: 'EASY_QUESTION_6',
    response: 'EASY_ANSWER_6',
  },
]

export const easyAgenciasFaqs = [
  {
    id: 1,
    question: 'EASY_QUESTION_1',
    response: 'EASY_ANSWER_1',
    minutesValue: 'EASY_ANSWER_1_P2',
  },
  {
    id: 2,
    question: 'EASY_QUESTION_2',
    response: 'EASY_ANSWER_2',
  },
  {
    id: 3,
    question: 'EASY_AGENCIAS_QUESTION_3',
    response: 'EASY_AGENCIAS_ANSWER_3',
  },
  {
    id: 4,
    question: 'EASY_AGENCIAS_QUESTION_4',
    response: 'EASY_AGENCIAS_ANSWER_4',
  },
  {
    id: 5,
    question: 'EASY_AGENCIAS_QUESTION_5',
    response: 'EASY_AGENCIAS_ANSWER_5',
  },
  {
    id: 6,
    question: 'EASY_AGENCIAS_QUESTION_6',
    response: 'EASY_AGENCIAS_ANSWER_6',
  },
]

export const htbFaqs = [
  {
    id: 1,
    question: 'HTB_FAQS_QUESTION_1',
    response: 'HTB_FAQS_ANSWER_1',
    minutesValue: durationInMinutes,
  },
  {
    id: 2,
    question: 'HTB_FAQS_QUESTION_2',
    response: 'HTB_FAQS_ANSWER_2',
  },
  {
    id: 3,
    question: 'HTB_FAQS_QUESTION_3',
    response: 'HTB_FAQS_ANSWER_3',
  },
  {
    id: 4,
    question: 'HTB_FAQS_QUESTION_4',
    response: 'HTB_FAQS_ANSWER_4',
  },
]

export const vtaFaqs = [
  {
    id: 1,
    question: 'SYC_FAQ_1_QUESTION',
    response: 'SYC_FAQ_1_RESPONSE_1',
    minutesValue: durationInMinutes,
  },
  {
    id: 2,
    question: 'SYC_FAQ_2_QUESTION',
    response: 'SYC_FAQ_2_RESPONSE_1',
  },
  {
    id: 3,
    question: 'SYC_FAQ_3_QUESTION',
    response: 'SYC_FAQ_3_RESPONSE_1',
    button: {
      href: '/vende-tu-auto',
      text: 'Cotiza ahora',
      variant: 'button',
    },
  },
  {
    id: 4,
    question: 'SYC_FAQ_4_QUESTION',
    response: 'SYC_FAQ_4_RESPONSE_1',
  },
]

export const vtaFaqsEasy = [
  {
    id: 1,
    sectionTitle: 'SYC_FAQ_1_QUESTION_EASY',
    questions: [
      {
        id: 1,
        question: 'SYC_FAQ_1_QUESTION_EASY',
        response: 'SYC_FAQ_1_RESPONSE_EASY',
      },
      {
        id: 2,
        question: 'SYC_FAQ_2_QUESTION_EASY',
        response: 'SYC_FAQ_2_RESPONSE_EASY',
      },
      {
        id: 3,
        question: 'SYC_FAQ_3_QUESTION_EASY',
        response: 'SYC_FAQ_3_RESPONSE_EASY',
      },
      {
        id: 4,
        question: 'SYC_FAQ_4_QUESTION_EASY',
        response: 'SYC_FAQ_4_RESPONSE_EASY',
      },
      {
        id: 5,
        question: 'SYC_FAQ_5_QUESTION_EASY',
        response: 'SYC_FAQ_5_RESPONSE_EASY',
      },
      {
        id: 6,
        question: 'SYC_FAQ_6_QUESTION_EASY',
        response: 'SYC_FAQ_6_RESPONSE_EASY',
      },
      {
        id: 7,
        question: 'SYC_FAQ_7_QUESTION_EASY',
        responseList: [
          'SYC_FAQ_7_RESPONSE_EASY_1',
          'SYC_FAQ_7_RESPONSE_EASY_2',
          'SYC_FAQ_7_RESPONSE_EASY_3',
          'SYC_FAQ_7_RESPONSE_EASY_4',
          'SYC_FAQ_7_RESPONSE_EASY_5',
          'SYC_FAQ_7_RESPONSE_EASY_6',
          'SYC_FAQ_7_RESPONSE_EASY_7',
          'SYC_FAQ_7_RESPONSE_EASY_8',
        ],
        note: 'SYC_FAQ_7_RESPONSE_EASY_9',
      },
      {
        id: 8,
        question: 'SYC_FAQ_8_QUESTION_EASY',
        response: 'SYC_FAQ_8_RESPONSE_EASY',
      },
      {
        id: 9,
        question: 'SYC_FAQ_9_QUESTION_EASY',
        responseList: [
          'SYC_FAQ_9_RESPONSE_EASY_1',
          'SYC_FAQ_9_RESPONSE_EASY_2',
          'SYC_FAQ_9_RESPONSE_EASY_3',
          'SYC_FAQ_9_RESPONSE_EASY_4',
          'SYC_FAQ_9_RESPONSE_EASY_5',
          'SYC_FAQ_9_RESPONSE_EASY_6',
          'SYC_FAQ_9_RESPONSE_EASY_7',
          'SYC_FAQ_9_RESPONSE_EASY_8',
          'SYC_FAQ_9_RESPONSE_EASY_9',
          'SYC_FAQ_9_RESPONSE_EASY_10',
          'SYC_FAQ_9_RESPONSE_EASY_11',
          'SYC_FAQ_9_RESPONSE_EASY_12',
        ],
      },
      {
        id: 10,
        question: 'SYC_FAQ_10_QUESTION_EASY',
        responseList: [
          'SYC_FAQ_10_RESPONSE_EASY_1',
          'SYC_FAQ_10_RESPONSE_EASY_2',
          'SYC_FAQ_10_RESPONSE_EASY_3',
          'SYC_FAQ_10_RESPONSE_EASY_4',
          'SYC_FAQ_10_RESPONSE_EASY_5',
          'SYC_FAQ_10_RESPONSE_EASY_6',
          'SYC_FAQ_10_RESPONSE_EASY_7',
          'SYC_FAQ_10_RESPONSE_EASY_8',
          'SYC_FAQ_10_RESPONSE_EASY_9',
          'SYC_FAQ_10_RESPONSE_EASY_10',
          'SYC_FAQ_10_RESPONSE_EASY_11',
          'SYC_FAQ_10_RESPONSE_EASY_12',
          'SYC_FAQ_10_RESPONSE_EASY_13',
          'SYC_FAQ_10_RESPONSE_EASY_14',
          'SYC_FAQ_10_RESPONSE_EASY_15',
          'SYC_FAQ_10_RESPONSE_EASY_16',
        ],
      },
    ],
  },
]

export const suscriptionFaqs = [
  {
    id: 1,
    question: 'SUSCRIPTION_FAQ_1_QUESTION',
    response: 'SUSCRIPTION_FAQ_1_RESPONSE',
  },
  {
    id: 2,
    question: 'SUSCRIPTION_FAQ_2_QUESTION',
    response: 'SUSCRIPTION_FAQ_2_RESPONSE',
  },
  {
    id: 3,
    question: 'SUSCRIPTION_FAQ_3_QUESTION',
    response: 'SUSCRIPTION_FAQ_3_RESPONSE',
  },
  {
    id: 4,
    question: 'SUSCRIPTION_FAQ_4_QUESTION',
    response: 'SUSCRIPTION_FAQ_4_RESPONSE',
  },
]

export const testimonials = [
  {
    rating: 5,
    videoUrl: '',
    name: 'Alejandro Padilla Cortez',
    title: 'Compró su Nissan Sentra 2018 con crédito ClikAuto.',
    text: 'Gracias por el apoyo, durante todo el proceso me explicaron paso a paso y pude estrenar mi',
    highlightedText: 'Sentra 2018 sin problemas.',
  },

  {
    rating: 5,
    videoUrl: '',
    name: 'Vanessa Andrade',
    title: 'Estrenó en un Clik la SUV que tanto quería.',
    text: 'Lo que más me gustó fue que respetaron los precios de la página y',
    highlightedText: 'mi presupuesto, y pude comprar mi Sportage  2018.',
  },

  {
    rating: 5,
    videoUrl: '',
    name: 'Luis Alberto Plasencia González',
    title: 'Estrenó  su Kicks 2018 a crédito en ClikAuto.',
    text: 'En todo momento fueron muy amables conmigo,',
    highlightedText: 'súper experiencia al comprar mi nueva SUV.',
  },

  {
    rating: 5,
    videoUrl: '',
    name: 'Icchel Nachieri Sarabia Márquez',
    title: 'Feliz  de tener  su nuevo Nissan Kicks.',
    text: 'Sin duda elegí comprar en ClikAuto, porque fue la',
    highlightedText:
      'plataforma que más seguridad me transmitía y la atención es excelente.',
  },
]

export const financingFaqsSection = [
  {
    question: 'FINANCING_QUESTION_1',
    response: 'FINANCING_ANSWER_1',
  },

  {
    question: 'FINANCING_QUESTION_2',
    response: 'FINANCING_ANSWER_2',
  },
]

export const financingFaqs = [
  {
    question: '¿Qué necesito para conseguir mi Crédito?',
    answer:
      'Documentos que ayuden a comprobar tu identidad, dónde vives y la capacidad de pago de acuerdo al plan que selecciones. Tramitar tu crédito es sencillo, fácil y rápido; tenemos el mejor plan para tu perfil. Si necesitas otra información llámanos al 8000302886 o envíanos un WhatsApp (5215541704164) y con gusto te ayudamos.',
  },

  {
    question:
      '¿Puedo hacer pagos anticipados o liquidar el crédito antes de que finalice mi plazo?',
    answer:
      'Claro, con nosotros puedes realizar pagos anticipados que van directo a tu deuda y también puedes liquidar tu crédito de forma anticipada sin ningún tipo de penalizaciones. ¡Llámanos al 8000302886 o envíanos un WhatsApp (5215541704164) y te explicamos cómo funciona!',
  },

  {
    question:
      '¿Pueden darme crédito si mi auto será para servicio público: taxi y/o plataformas?',
    answer:
      'Claro, contamos con planes especiales para servicio público, plataformas y vehículos con conversión a gas natural. ¡Llámanos al 8000302886 o envíanos un WhatsApp (5215541704164) y elige el mejor plan para ti!',
  },

  {
    question: '¿Puedo tener crédito si soy comerciante o independiente?',
    answer:
      '¡Por supuesto que sí! Tenemos el mejor plan de crédito para emprendedores, nosotros nos ocupamos de la movilidad de tu negocio para que consigas el mayor crecimiento y éxito. ¡Llámanos al 80000302886 o envíanos un WhatsApp (52 1 31103600), queremos ser parte de tu éxito!',
  },

  {
    question: 'No estoy tan bien en buró de crédito, ¿me pueden dar crédito?',
    answer:
      'Seguro que te lo podemos dar, nuestros créditos son personalizados para cada uno de nuestros clientes. ¡Llámanos al 800 030 2886 o envíanos un WhatsApp (52 1 31103600), queremos conocerte y ofrecerte las mejores opciones de crédito para ti!',
  },
]

export const simplicityPrivacyContent = `
  <p>AUTOCOM SEMINUEVOS, S.A.P.I. DE C. V., conocida por  su nombre Comercial  "CLIKAUTO" con domicilio en <strong>Blvd Manuel Ávila Camacho 810, Col. Las Margaritas, Tlalnepantla de Baz, Estado de México, 54050 </strong> es responsable de los datos personales de sus clientes no activos y clientes potenciales, por lo que está comprometido permanentemente para salvaguardarlos.</p>

  <p>ClikAuto como responsable de sus datos personales, hace de su conocimiento, que la finalidad de este documento es utilizar sus datos personales, (tales como: nombre, edad, sexo, estado civil, domicilio, correo electrónico, teléfono celular, y fijo, RFC, CURP, fotografías y usuarios de redes sociales como facebook e instagram) para:</p>
  <br />
  
  <ul>
    <li>Realizar actividades de mercadeo y promoción en general (p. Ej: informarle sobre promociones especiales e invitarlo a lanzamientos y eventos de nuestros automóviles).</li>
    <li>Generar actividades publicitarias para ofrecerle productos y servicios así como información de nuestros socios de negocios.
    </li>
    <li>Prospección comercial, análisis estadísticos y de mercado.</li>
    <li>Obtener retroalimentación de usted para mejorar nuestros productos.</li>
  </ul>

  <br />

  <p>El titular de datos personales que establece una relación con ClikAuto y sus sociedades controladoras, filiales, subsidiarias y afiliadas, también con sus proveedores y socios de negocios, acepta que sus datos personales podrán ser transferidos en los términos descritos en la Ley, su Reglamento y el presente Aviso de Privacidad.</p>
  
  <p>En caso de que usted desee conocer el texto completo del Aviso de Privacidad de ClikAuto y quiera ejercer su derecho ARCO (Acceso, Rectificación, Cancelación y Oposición) para controlar el uso que se le esté dando a sus datos personales le invitamos a que nos contacte al teléfono: <a href="tel:5589571916">5589571916</a>, así como al correo electrónico <a href="mailto:hola@clikauto.com">hola@clikauto.com</a> y en la página web <a href="https://clikauto.com">clikauto.com</a></p>

  <p>Las transacciones de pago electrónico dentro del sitio se llevarán a cabo mediante la pasarela de pagos de Openpay.</p>

  <p>Este aviso de privacidad podrá ser modificado por ClikAuto, dichas modificaciones serán oportunamente informados a través de un comunicado, o cualquier otro medio de comunicación que ClikAuto, determine para tal efecto</p>
  <br />
 
`

export const privacyContent = `
  <p>En cumplimiento a lo previsto en la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, nos permitimos informarle lo siguiente:</p>
  
  <p>AUTOCOM SEMINUEVOS, S.A.P.I. DE C. V.,conocida por  su nombre Comercial  "CLIKAUTO" con domicilio en <strong>Blvd Manuel Ávila Camacho 810, Col. Las Margaritas, Tlalnepantla de Baz, Estado de México, 54050 </strong> es responsable de recabar sus datos personales, del tratamiento que se les da a los mismos y de su adecuada protección, para sí o para cualquiera de las sociedades mercantiles que sean sus subsidiarias o filiales.</p>
  <br />

  <h5>I. FINALIDADES DEL TRATAMIENTO Y DATOS PERSONALES QUE SE RECABAN.</h5>
  
  <p>Los datos personales que recabamos de usted quedarán incorporados a una base de datos que es propiedad de Autocom, mismos que son: (i) nombre completo, (ii) domicilio particular, (iii) Registro Federal de Contribuyentes (RFC), (iv) Clave única de Registro de Población (CURP), (v) fecha de nacimiento, (vi) teléfono particular, (vii) teléfono celular, (viii) fotografías, (ix) redes sociales como facebook, twitter, whatsapp e Instagram (x) información crediticia y (xi) su correo electrónico personal.</p>
  
  <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades primarias que son necesarias para los productos y/o servicios que solicita:</p>
  <br />
  <ul>
    <li>Para identificación.</li>
    <li>Verificar su situación patrimonial.</li>
    <li>Para recordarle de servicios de mantenimiento.</li>
    <li>Para hacer válida la garantía de su vehículo.</li>
    <li>Para informarle acerca de llamados a revisión de su vehículo.</li>
    <li>Para realizar acciones de actualizaciones técnicas de los bienes o servicios que se hubiese adquirido.</li>
    <li>Para dar seguimiento a solicitudes, consultas o reclamaciones, participación en actividades y dinámicas, así como, dar seguimiento a nuestra relación comercial.</li>
    <li>Para mantener actualizados los registros.</li>
  </ul>
  <br />

  <p>De manera adicional, utilizaremos sus datos personales para las siguientes finalidades secundarias que no son necesarias para los productos y o servicios solicitados, pero que nos permiten y facilitan brindarle una mejor atención:</p>
  <br />
  <ul>
    <li>Para ofrecer bienes y servicios.</li>
    <li>Para realizar estudios cuantitativos y cualitativos (investigación de mercados).</li>
    <li>Para realizar encuestas de satisfacción de bienes y servicios.</li>
    <li>Para invitarlo a eventos y enviar información sobre promociones, productos y servicios adicionales que consideremos pueden ser de su interés.</li>
    <li>Para fines mercadotécnicos y publicitarios.</li>
    <li>Para prospección comercial.</li>
  </ul>
  <br />

  <p>Adicionalmente, si usted no se opone, se utilizará su información personal para remitirle información personal, publicidad, encuestas, evaluaciones, y demás información relacionada con nuestros servicios.</p>
  <br />

  <h5>II. DERECHOS ARCO Y/O REVOCACIÓN DE CONSENTIMIENTO.</h5>

  <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación). Que la presente información la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación). Así mismo, usted puede oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>

  <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del número telefónico <a href="tel:8002762886">800 276 2886</a> de la Ciudad de México o enviando un correo electrónico a <a href="mailto:datos.personales@autocom.mx" target="_blank">datos.personales@autocom.mx</a></p>
  <br />

  <h5>III. REVOCACIÓN DE CONSENTIMIENTO SOBRE EL USO DE SUS DATOS PERSONALES.</h5>
  <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros. Para revocar su consentimiento deberá presentar su solicitud a través del siguiente correo electrónico <a href="mailto:datos.personales@autocom.mx" target="_blank">datos.personales@autocom.mx</a> Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos que nos remita una solicitud de revocación sobre el uso de sus datos personales, copia simple de la documentación que acredite su persona o representación legal, nombre completo, correo electrónico, y número de teléfono.</p>
  <br />

  <h5>IV. LIMITACIÓN Y/O DIVULGACIÓN DE SUS DATOS PERSONALES.</h5>
  <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, deberá presentar una solicitud en <a href="mailto:datos.personales@autocom.mx" target="_blank">datos.personales@autocom.mx</a>, indicando su nombre completo y su petición.</p>
  <br />

  <h5>V. MODIFICACIONES AL PRESENTE AVISO DE PRIVACIDAD.</h5>
  <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos, de nuestras prácticas de privacidad, de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de la siguiente página web <a href="https://www.ClikAuto.com">www.ClikAuto.com</a></p>

  <p>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad será por medio de comunicado oficial por parte de un representante legal de ClikAuto, publicado en la página web oficial, en dónde se les notifique a los titulares el cambio y/o modificación al presente aviso de privacidad</p> 

  <p>Con el presente Aviso de Privacidad, los titulares de la información quedan debidamente notificados e informados sobre los datos que se recabaron de ellos y su finalidad, aceptando los términos que fueron recabados, en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento.</p>

  <p>Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en el presente aviso de privacidad.</p>
  <br />
  <br />
`

export const vdpFinancingFaqs = [
  {
    question: 'VDP_FINANCING_FAQS_FAQ_TITLE_1',
    answer: `
    <ul>
      <li>Identificación oficial / INE/IFE, Cédula profesional o Pasaporte vigente.</li>
      <li>Comprobante de domicilio / CFE, Agua o Telmex menor a 3 meses.</li>
      <li>Comprobante de ingresos / 3 últimos recibos de nómina y/o 3 últimos estados de cuenta bancaria: carátula y reflejo de depósitos.</li>
      <li>Licencia de conducir / Conductor o chofer vigente.</li>
    </ul>
    <p>Si eres PFAE y/o extranjero también necesitarás:</p>
    <ul>
      <li>Persona física con actividad empresarial / PFAE Declaración anual ISR y constancia de situación fiscal (con cédula de identificación fiscal incluida).</li>
      <li>Extranjero / Forma migratoria: FM2-FM3.</li>
    </ul>
    <p>Es muy importante tengas a la mano esta documentación cuando solicites tu crédito. </p> 
    `,
    chatButton: false,
  },
  {
    question: 'VDP_FINANCING_FAQS_FAQ_TITLE_2',
    answer: `
    <p>Es muy bueno para tu crédito que puedas comprobar ingresos por nómina o estado de cuenta bancario.</p>
    <p>¿Eres emprendedor o independiente? No te preocupes, nos encanta apoyar a personas con tu perfil; juntos encontraremos el mejor plan para que puedas estrenar tu auto y tu negocio pueda crecer.
    </p>
    `,
    chatButton: false,
  },
  {
    question: 'VDP_FINANCING_FAQS_FAQ_TITLE_3',
    answer: `
    <p>Mantener un buen perfil en buró de crédito, ayuda muchísimo al momento de solicitar tu crédito. Esto acelera tu proceso y la autorización es casi inmediata.</p>
    <p>No te preocupes si tu historial no es perfecto o muy bueno, juntos encontraremos la mejor opción para que estrenes.</p>
    `,
    chatButton: false,
  },
]

export const makes = [
  'ACURA',
  'ALFA ROMEO',
  'AUDI',
  'BAIC',
  'BMW',
  'BUICK',
  'CADILLAC',
  'CHEVROLET',
  'CHRYSLER',
  'CUPRA',
  'DODGE',
  'FAW',
  'FIAT',
  'FORD',
  'GMC',
  'HONDA',
  'HUMMER',
  'HYUNDAI',
  'INFINITI',
  'ISUZU',
  'JAC',
  'JAGUAR',
  'JEEP',
  'KIA',
  'LAND ROVER',
  'LEXUS',
  'LINCOLN',
  'MAZDA',
  'MERCEDES BENZ',
  'MERCURY',
  'MINI',
  'MITSUBISHI',
  'NISSAN',
  'PEUGEOT',
  'PONTIAC',
  'PORSCHE',
  'RAM',
  'RENAULT',
  'SAAB',
  'SEAT',
  'SMART',
  'SUBARU',
  'SUZUKI',
  'TESLA',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
]

export const makesSYC = [
  {
    image: '/img/sell-your-car/makes/chevrolet.png',
    name: 'CHEVROLET',
  },
  {
    image: '/img/sell-your-car/makes/volkswagen.png',
    name: 'VOLKSWAGEN',
  },
  {
    image: '/img/sell-your-car/makes/nissan.png',
    name: 'NISSAN',
  },
  {
    image: '/img/sell-your-car/makes/audi.png',
    name: 'AUDI',
  },
  {
    image: '/img/sell-your-car/makes/acura.png',
    name: 'ACURA',
  },
  {
    image: '/img/sell-your-car/makes/honda.png',
    name: 'HONDA',
  },
  {
    image: '/img/sell-your-car/makes/ford.png',
    name: 'FORD',
  },
  {
    image: '/img/sell-your-car/makes/toyota.png',
    name: 'TOYOTA',
  },
  {
    image: '/img/sell-your-car/makes/mazda.png',
    name: 'MAZDA',
  },
  {
    image: '/img/sell-your-car/makes/bmw.png',
    name: 'BMW',
  },
  {
    image: '/img/sell-your-car/makes/kia.png',
    name: 'KIA',
  },
  {
    image: '/img/sell-your-car/makes/fiat.png',
    name: 'FIAT',
  },
  {
    image: '/img/sell-your-car/makes/mercedes-benz.png',
    name: 'MERCEDES BENZ',
  },
  {
    image: '/img/sell-your-car/makes/seat.png',
    name: 'SEAT',
  },
  {
    image: '/img/sell-your-car/makes/smart.png',
    name: 'SMART',
  },
  {
    image: '/img/sell-your-car/makes/suzuki.png',
    name: 'SUZUKI',
  },
]

export const pressSYC = [
  {
    image: '/img/sell-your-car/press/AIMGroup.png',
    name: 'AIM Group',
    url: 'https://aimgroup.com/2021/08/09/clikauto-launches-in-four-mexican-regions/',
  },
  {
    image: '/img/sell-your-car/press/MarketsInsider.png',
    name: 'Markets Insider',
    url: 'https://markets.businessinsider.com/news/stocks/clikauto-democratizes-safe-and-transparent-used-car-buying-in-mexico-1030690581',
  },
  {
    image: '/img/sell-your-car/press/Elsoldemexico.png',
    name: 'El sol de mexico',
    url: 'https://www.elsoldemexico.com.mx/deportes/automotriz/clikauto-democratiza-la-compra-venta-segura-de-autos-usados-en-mexico-7065940.html',
  },
  {
    image: '/img/sell-your-car/press/Yahoo!.png',
    name: 'yahoo!',
    // url: 'https://www.mural.com.mx/libre/online07/aplicacionEI/webview/compartir/ImagenEdImpresa.aspx?Titulo=Automotriz&Resumen=13%20de%20mayo&img=https://hemerotecalibre.mural.com.mx/20230513/large/MAUT20230513-002.JPG&md5=b7474362a311d8f77b5b2f9394d762cb',
  },
  {
    image: '/img/sell-your-car/press/Milenio.png',
    name: 'Milenio',
    url: 'https://www.milenio.com/negocios/clikauto-busca-aprovechar-auge-autos-usados-mexico',
  },
  {
    image: '/img/sell-your-car/press/Reforma.png',
    name: 'Reforma',
    url: 'https://www.reforma.com/libre/online07/aplicacionEI/webview/compartir/ImagenEdImpresa.aspx?Titulo=Automotriz&Resumen=13%20de%20mayo&img=https://hemerotecalibre.reforma.com/20230513/large/RAUT20230513-002.JPG&md5=0af78bf955f9ddb3bbdc7f7040c1f2b3',
  },
  {
    image: '/img/sell-your-car/press/alianza-automotriz.png',
    name: 'alianza automotriz',
    // url: 'https://www.mural.com.mx/libre/online07/aplicacionEI/webview/compartir/ImagenEdImpresa.aspx?Titulo=Automotriz&Resumen=13%20de%20mayo&img=https://hemerotecalibre.mural.com.mx/20230513/large/MAUT20230513-002.JPG&md5=b7474362a311d8f77b5b2f9394d762cb',
  },
  // {
  //   image: '/img/sell-your-car/press/Profeco.png',
  //   name: 'profeco',
  //   url: 'https://distintivodigital.profeco.gob.mx/consulta/DA23QQE0871047',
  // },
  // {
  //   image: '/img/sell-your-car/press/Profeco-2.png',
  //   name: 'profeco2',
  //   url: 'https://distintivodigital.profeco.gob.mx/consulta/DA23QQE0871047',
  // },
]

export const carsSold = [
  {
    id: 1,
    image: '/img/sell-your-car/carsSold/01-Chevrolet-Matiz-2014.webp',
    daysNumbers: null,
    hoursNumbers: '3',
    days: false,
    hours: true,
    price: 'SYC_VEHICLES_SOLD_PRICE_1',
    vehicle: 'Chevrolet Matiz 2014',
    image2: '/img/sell-your-car/carsSold/02-Mazda-Mazda3-2011.webp',
    daysNumbers2: '1',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_2',
    vehicle2: 'Mazda Mazda3 2011',
  },
  {
    id: 2,
    image: '/img/sell-your-car/carsSold/03-Dodge-Attitude-2008.webp',
    daysNumbers: '2',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_3',
    vehicle: 'Dogde Attitude 2008',
    image2: '/img/sell-your-car/carsSold/04-Audi-A1-2012.webp',
    daysNumbers2: '2',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_4',
    vehicle2: 'Audi A1 2012',
  },
  {
    id: 3,
    image: '/img/sell-your-car/carsSold/05-Fiat-500-2012.webp',
    daysNumbers: '3',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_5',
    vehicle: 'Fiat 500 2012',
    image2: '/img/sell-your-car/carsSold/06-Renault-Stepway-2013.webp',
    daysNumbers2: '3',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_6',
    vehicle2: 'Renault Stepway 2013',
  },
  {
    id: 4,
    image: '/img/sell-your-car/carsSold/07-Nissan-Versa-2014.webp',
    daysNumbers: '4',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_7',
    vehicle: 'Nissan Versa 2014',
    image2: '/img/sell-your-car/carsSold/08-Fiat-Mobi-2020.webp',
    daysNumbers2: '5',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_8',
    vehicle2: 'Fiat Mobi 2020',
  },
  {
    id: 5,
    image: '/img/sell-your-car/carsSold/09-Chevrolet-Chevy-2009.webp',
    daysNumbers: null,
    hoursNumbers: '7',
    days: false,
    hours: true,
    price: 'SYC_VEHICLES_SOLD_PRICE_9',
    vehicle: 'Chevrolet Chevy 2009',
    image2: '/img/sell-your-car/carsSold/10-Dodge i10 2013.webp',
    daysNumbers2: '7',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_10',
    vehicle2: 'Dodge i10 2013',
  },
  {
    id: 6,
    image: '/img/sell-your-car/carsSold/11-Chevrolet-Chevy-2010.webp',
    daysNumbers: '11',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_11',
    vehicle: 'Chevrolet Chevy 2010',
    image2: '/img/sell-your-car/carsSold/12-Chevrolet-Cruze-2013.webp',
    daysNumbers2: '6',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_12',
    vehicle2: 'Chevrolet Cruze 2013',
  },
  {
    id: 7,
    image: '/img/sell-your-car/carsSold/13-Chevrolet-Aveo-2013.webp',
    daysNumbers: '7',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_13',
    vehicle: 'Chevrolet Aveo 2013',
    image2: '/img/sell-your-car/carsSold/14-Chevrolet-Sonic-2015.webp',
    daysNumbers2: '29',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_14',
    vehicle2: 'Chevrolet Sonic 2015',
  },
  {
    id: 8,
    image: '/img/sell-your-car/carsSold/15-Volkswagen-Bora-2010.webp',
    daysNumbers: '6',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_15',
    vehicle: 'Volkswagen Bora 2010',
    image2: '/img/sell-your-car/carsSold/16-Nissan-Rogue-2012.webp',
    daysNumbers2: '4',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_16',
    vehicle2: 'Nissan Rogue 2012',
  },
  {
    id: 9,
    image: '/img/sell-your-car/carsSold/17-Renault-Koleos-2014.webp',
    daysNumbers: '27',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_17',
    vehicle: 'Renault Koleos 2014',
    image2: '/img/sell-your-car/carsSold/18-Volkswagen-Vento-2018.webp',
    daysNumbers2: '2',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_18',
    vehicle2: 'Volkswagen Vento 2018',
  },
  {
    id: 10,
    image: '/img/sell-your-car/carsSold/19-Ford-Escape-2009.webp',
    daysNumbers: '16',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_19',
    vehicle: 'Ford Escape 2009',
    image2: '/img/sell-your-car/carsSold/20-Chrevrolet-Aveo-2019.webp',
    daysNumbers2: '17',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_20',
    vehicle2: 'Chrevrolet Aveo 2019',
  },
  {
    id: 11,
    image: '/img/sell-your-car/carsSold/21-Peugeot-Partner-2016.webp',
    daysNumbers: '6',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_21',
    vehicle: 'Peugeot Partner 2016',
    image2: '/img/sell-your-car/carsSold/22-Honda-Fit-2015.webp',
    daysNumbers2: '10',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_22',
    vehicle2: 'Honda Fit 2015',
  },
  {
    id: 12,
    image: '/img/sell-your-car/carsSold/23-Nissan-Versa-2017.webp',
    daysNumbers: '13',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_23',
    vehicle: 'Nissan Versa 2017',
    image2: '/img/sell-your-car/carsSold/24-Audi-Q5-2020.webp',
    daysNumbers2: '42',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_24',
    vehicle2: 'Audi Q5 2020',
  },
  {
    id: 13,
    image: '/img/sell-your-car/carsSold/25-Ford-Figo-2018.webp',
    daysNumbers: '35',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_25',
    vehicle: 'Ford Figo 2018',
    image2: '/img/sell-your-car/carsSold/26-Volkswagen-Vento-2018.webp',
    daysNumbers2: '3',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_26',
    vehicle2: 'Volkswagen Vento 2018',
  },
  {
    id: 14,
    image: '/img/sell-your-car/carsSold/27-Peugeot-Partner-2017.webp',
    daysNumbers: '1',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_27',
    vehicle: 'Peugeot Partner 2017',
    image2: '/img/sell-your-car/carsSold/28-Volkswagen-Passat-2016.webp',
    daysNumbers2: '2',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_28',
    vehicle2: 'Volkswagen Passat 2016',
  },
  {
    id: 15,
    image: '/img/sell-your-car/carsSold/29-Dodge-Journey-2013.webp',
    daysNumbers: '20',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_29',
    vehicle: 'Dodge Journey 2013',
    image2: '/img/sell-your-car/carsSold/30-Chrevrolet-Aveo-2018.webp',
    daysNumbers2: '25',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_30',
    vehicle2: 'Chrevrolet Aveo 2018',
  },
  {
    id: 16,
    image: '/img/sell-your-car/carsSold/31-Peugeot-Partner-2017.webp',
    daysNumbers: '13',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_31',
    vehicle: 'Volkswagen Crossfox 2017',
    image2: '/img/sell-your-car/carsSold/32-Hyundai-Elantra-2017.webp',
    daysNumbers2: '41',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_32',
    vehicle2: 'Hyundai Elantra 2017',
  },
  {
    id: 17,
    image: '/img/sell-your-car/carsSold/33-MG-ZS-2019.webp',
    daysNumbers: '60',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_33',
    vehicle: 'MG ZS 2023 2019',
    image2: '/img/sell-your-car/carsSold/34-Kia-Forte-2018.webp',
    daysNumbers2: '55',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_34',
    vehicle2: 'Kia Forte 2018',
  },
  {
    id: 18,
    image: '/img/sell-your-car/carsSold/35-Toyota-Sienna-2011.webp',
    daysNumbers: '10',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_35',
    vehicle: 'Toyota Sienna 2011',
    image2: '/img/sell-your-car/carsSold/36-Ford-Ranger-2013.webp',
    daysNumbers2: '17',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_36',
    vehicle2: 'Ford Ranger 2013',
  },
  {
    id: 19,
    image: '/img/sell-your-car/carsSold/37-Volkswagen-Golf-2016.webp',
    daysNumbers: '48',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_37',
    vehicle: 'Volkswagen Golf 2016',
    image2: '/img/sell-your-car/carsSold/38-Buick-Verano-2016.webp',
    daysNumbers2: '37',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_38',
    vehicle2: 'Buick Verano 2016',
  },
  {
    id: 20,
    image: '/img/sell-your-car/carsSold/39-Mercedez-A200-2020.webp',
    daysNumbers: '19',
    hoursNumbers: null,
    days: true,
    hours: false,
    price: 'SYC_VEHICLES_SOLD_PRICE_39',
    vehicle: 'Mercedez A200 2020',
    image2: '/img/sell-your-car/carsSold/40-Cadillac-Escalade-2012.webp',
    daysNumbers2: '15',
    hoursNumbers2: null,
    days2: true,
    hours2: false,
    price2: 'SYC_VEHICLES_SOLD_PRICE_40',
    vehicle2: 'Cadillac Escalade 2012',
  },
]

export const states = [
  'Distrito Federal',
  'Estado De México',
  'Michoacán',
  'Querétaro',
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Chiapas',
  'Chihuahua',
  'Coahuila',
  'Colima',
  'Durango',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
]
export const distritoFederal = 'Distrito Federal'
export const estadoMexico = 'Estado De México'
export const michoacan = 'Michoacán'
export const queretaro = 'Querétaro'
export const googleReference = 'ChIJc8O7S4YD0oUR7OoUcIInjCo'
export const googleMapsApiKey = 'AIzaSyBBmywcXPu2-FvA7rU9j2iwKl9BU_hq0HU'

export const featureFlags = {
  isLocationsByDateTimeProd: true,
}

export const locations = [
  // {
  //   company_id: 4,
  //   branch_id: 4,
  //   name: 'EDO MEX Satélite',
  //   photo_url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/CK_Satelite.png',
  //   branch_type: 'externa',
  //   review_minutes_duration: 30,
  //   anticipation_days: 1,
  //   address: {
  //     zip_code: '54050',
  //     latitude: 19.5278045,
  //     longitude: -99.2241891,
  //     country_name: 'Mexico',
  //     state_name: 'Estado de Mexico',
  //     city_name: 'Tlalnepantla de Baz',
  //     neighborhood: 'Tlalnepantla de Baz',
  //     street_name: 'Perif. Blvd. Manuel Ávila Camacho',
  //     external_number: '810Hab',
  //     internal_number: '',
  //     formatted_address: '',
  //   },
  // },
  {
    company_id: 5,
    branch_id: 5,
    name: 'EDO MEX Tepotzotlán (Astrollantas)',
    photo_url:
      'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/AS_Tepozotlan.png',
    branch_type: 'externa',
    review_minutes_duration: 60,
    anticipation_days: 1,
    address: {
      zip_code: '54600',
      latitude: 19.72062,
      longitude: -98.21216,
      country_name: 'Mexico',
      state_name: 'Estado de Mexico',
      city_name: 'Tepotzotlán',
      neighborhood: 'Tepotzotlán',
      street_name: 'Autopista Querétaro - México Km 42.5',
      external_number: '-',
      internal_number: '',
      formatted_address: '',
    },
  },
  {
    company_id: 5,
    branch_id: 6,
    name: 'EDO MEX Echegaray (Astrollantas)',
    photo_url:
      'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/Astrollantas.png',
    branch_type: 'externa',
    review_minutes_duration: 60,
    anticipation_days: 1,
    address: {
      zip_code: '53020',
      latitude: 19.51452,
      longitude: -102.24516,
      country_name: 'Mexico',
      state_name: 'Estado de Mexico',
      city_name: 'Sta Maria Nativitas',
      neighborhood: 'Sta Maria Nativitas',
      street_name: 'Av. Gustavo Baz Prada',
      external_number: '65',
      internal_number: '',
      formatted_address: '',
    },
  },
  {
    company_id: 5,
    branch_id: 7,
    name: 'CDMX Central Abastos (Astrollantas)',
    photo_url:
      'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/AS_CentralAbastos.png',
    branch_type: 'externa',
    review_minutes_duration: 60,
    anticipation_days: 1,
    address: {
      zip_code: '09310',
      latitude: 17.38099,
      longitude: -99.07701,
      country_name: 'Mexico',
      state_name: 'CDMX',
      city_name: 'Ciudad de México',
      neighborhood: 'Ciudad de México',
      street_name: 'Avenida Rojo Gomez # 30 Esq',
      external_number: '-',
      internal_number: '',
      formatted_address: '',
    },
  },
]

export const simplifiedStatusBar = [
  {
    title: 'Datos',
    value: 1,
  },
  {
    title: 'Ofertas',
    value: 2,
  },
  {
    title: 'Inspección',
    value: 3,
  },
]

export const simplifiedCheckoutBar = [
  {
    title: 'Datos',
    value: 1,
  },
  {
    title: 'Extras',
    value: 2,
  },
  {
    title: 'Auto a cuenta',
    value: 3,
  },
  {
    title: 'Pago',
    value: 4,
  },
]
export const urlCredito = 'credito-autos-seminuevos'
export const urlSeminuevos = 'autos-seminuevos'

export const socialMediaSeoTagsHome = {
  ogTitle: 'Compra y venta de coches con garantía en ClikAuto',
  ogUrl: 'https://clikauto.com',
  ogDescription:
    'Compra venta de coches con ClikAuto. Siempre el mejor precio, garantía y planes de pago. ¡Obtén en línea y en minutos el mejor valor estimado por tu auto!',
  ogType: 'bussines.bussines',
  ogImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
  twitterTitle: 'Compra y venta de coches con garantía en ClikAuto',
  twitterDescription:
    'Compra venta de coches con ClikAuto. Siempre el mejor precio, garantía y planes de pago. ¡Obtén en línea y en minutos el mejor valor estimado por tu auto!',
  twitterImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
}

export const socialMediaSeoTagsEasy = {
  ogSiteName: 'Easy by ClikAuto',
  ogTitle:
    '¿Qué es vender un auto a consignación con easy de ClikAuto? Ventajas y consejos.',
  ogUrl: 'https://clikauto.com/easy',
  ogDescription:
    'Vender un auto por tu cuenta representa una gran inversión de esfuerzo y de tiempo para mostrarlo, además de que puede llegar a ser peligroso. Por eso vender tu auto con easy de ClikAuto a consignación puede ser una gran alternativa a cualquier forma tradicional que existe.',
  ogType: 'bussines.bussines',
  ogImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/Vende_tu_auto_Con_Easy.webp',
  twitterTitle:
    '¿Qué es vender un auto a consignación con easy de ClikAuto? Ventajas y consejos.',
  twitterDescription:
    'Vender un auto por tu cuenta representa una gran inversión de esfuerzo y de tiempo para mostrarlo, además de que puede llegar a ser peligroso. Por eso vender tu auto con easy de ClikAuto a consignación puede ser una gran alternativa a cualquier forma tradicional que existe.',
  twitterImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/Vende_tu_auto_Con_Easy.webp',
}

export const socialMediaSeoTagsEasyAgencias = {
  ogSiteName: 'Easy Agencias by ClikAuto',
  ogTitle: 'Te ayudamos a vender más autos rápido y seguro',
  ogUrl: 'https://clikauto.com/easy-agencias',
  ogDescription:
    'Somos tu nuevo aliado para vender más autos. Más de 10 mil visitantes en nuestra página web buscan un auto nuevo.',
  ogType: 'bussines.bussines',
  ogImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/Vende_tu_auto_Con_Easy.webp',
  twitterTitle: 'Te ayudamos a vender más autos rápido y seguro',
  twitterDescription:
    'Somos tu nuevo aliado para vender más autos. Más de 10 mil visitantes en nuestra página web buscan un auto nuevo.',
  twitterImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/Vende_tu_auto_Con_Easy.webp',
}

export const jsonLDHome = {
  type: 'Organization',
  name: 'ClikAuto',
  url: 'https://clikauto.com',
  logo: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
  image:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
  description:
    'Compra, vende o cambia tu auto rápido, seguro y al mejor precio',
  telephone: '55 8957 1916',
  showSecondScript: true,
  secondScriptData: {
    '@context': 'http://www.schema.org',
    '@type': 'WebSite',
    name: 'ClikAuto',
    alternateName: 'ClikAuto',
    url: 'https://clikauto.com',
  },
}

export const socialMediaSeoTagsVTA = {
  ogTitle: 'Vende tu auto al mejor precio. Pago rápido y seguro',
  ogUrl: 'https://clikauto.com/vende-tu-auto',
  ogDescription:
    'Vende tu auto al precio que quieres. Te ayudamos a vender tu auto en tiempo record y 100% seguro. Vender auto, cualquier marca, modelo o kilometraje.',
  ogType: 'website',
  ogImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/clikauto.webp',
  twitterTitle: 'Vende tu auto al mejor precio. Pago rápido y seguro',
  twitterDescription:
    'Vende tu auto al precio que quieres. Te ayudamos a vender tu auto en tiempo record y 100% seguro. Vender auto, cualquier marca, modelo o kilometraje.',
  twitterImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/clikauto.webp',
}

export const jsonLDVTA = {
  type: 'Organization',
  name: 'ClikAuto',
  url: 'https://clikauto.com',
  logo: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
  image:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
  description:
    'Compra, vende o cambia tu auto rápido, seguro y al mejor precio',
  telephone: '55 8957 1916',
  showSecondScript: true,
  secondScriptData: {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: '¿Dónde puedo vender mi auto?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'En ClikAuto estamos democratizando la compra venta de autos. Compramos tu auto de forma rápida, segura y al mejor precio. Te pagamos el mismo día. Únete a nuestros cientos de clientes contentos y recibe el mejor valor estimado por tu auto aquí',
        },
      },
      {
        '@type': 'Question',
        name: '¿Cómo vender mi auto?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Ingresa marca, modelo y kilometraje. Recibe un valor estimado en 3 minutos. Agenda una inspección a domicilio o en nuestras sucursales. La inspección dura 90 minutos. Incluye revisión mecánica y estética. Firma contrato y recibe tu dinero el mismo día',
        },
      },
    ],
  },
}

export const socialMediaSeoTagsFinancing = {
  ogTitle: 'Autos seminuevos a Crédito. Hasta 60 meses para pagar',
  ogUrl: 'https://clikauto.com/credito',
  ogDescription:
    'Crédito para autos seminuevos. Elige el auto de tus sueños. Personaliza enganche, plazo y monto de tus mensualidades. Llévate un seminuevo garantizado a pagos',
  ogType: 'website',
  ogImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/documentos_clikauto.webp',
  twitterTitle: 'Autos seminuevos a Crédito. Hasta 60 meses para pagar',
  twitterDescription:
    'Cotiza el valor de tu auto aquí. Ingresa marca, modelo, año y Km, recibe un valor estimado en línea en 3 minutos. Recibe tu pago en 4 horas',
  twitterImage:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/clikauto.webp',
}

export const jsonLDSRP = {
  type: 'Organization',
  name: 'ClikAuto',
  url: 'https://clikauto.com',
  logo: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
  image:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
  description:
    'Compra, vende o cambia tu auto rápido, seguro y al mejor precio',
  telephone: '55 8957 1916',
  showSecondScript: true,
  secondScriptData: {
    '@context': 'http://www.schema.org',
    '@type': 'WebSite',
    name: 'ClikAuto',
    alternateName: 'ClikAuto',
    url: 'https://clikauto.com',
  },
}

export const jsonLDFinancing = {
  type: 'Organization',
  name: 'ClikAuto',
  url: 'https://clikauto.com',
  logo: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
  image:
    'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/imagen-vta.webp',
  description:
    'Compra, vende o cambia tu auto rápido, seguro y al mejor precio',
  telephone: '55 8957 1916',
  addressCountry: 'MX',
  showSecondScript: true,
  secondScriptData: {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: 'Crédito para autos seminuevos',
    image:
      'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/imagen-vta.webp',
    description:
      'Llévate un seminuevo con solo el 20% de enganche. Personaliza y elige tus mensualidades y pagos. Paga tu auto en hasta 60 meses.',
    brand: {
      '@type': 'Brand',
      name: 'ClikAuto',
    },
  },
  showThirdScript: true,
}
export const phones = [
  {
    id: 1,
    fuente: 'Mailing',
    utm_source: 'mailing',
    utm_medium: 'correo',
    num_llamada_ventas: '525588543323',
    num_llamada_compras: '525588544135',
    num_whatsapp_ventas: '52559302 0778',
    num_whatsapp_compras: '525593020763',
    created_at: '2022-07-07T16:39:10.670-05:00',
    updated_at: '2022-07-22T11:47:57.614-05:00',
  },
  {
    id: 2,
    fuente: 'Goog Ads ',
    utm_source: 'google',
    utm_medium: 'cpc',
    num_llamada_ventas: '525588543324',
    num_llamada_compras: '525588544136',
    num_whatsapp_ventas: '52559302 0788',
    num_whatsapp_compras: '525569437810',
    created_at: '2022-07-25T08:46:14.312-05:00',
    updated_at: '2022-07-25T08:46:14.312-05:00',
  },
  {
    id: 3,
    fuente: 'Google Maps',
    utm_source: 'google',
    utm_medium: 'mybusiness',
    num_llamada_ventas: '525588543326',
    num_llamada_compras: '525588544310',
    num_whatsapp_ventas: '525593020778',
    num_whatsapp_compras: '525593020736',
    created_at: '2022-07-25T08:47:24.946-05:00',
    updated_at: '2022-07-25T08:47:24.946-05:00',
  },
  {
    id: 4,
    fuente: 'Facebook Orgánico',
    utm_source: 'facebook',
    utm_medium: 'post',
    num_llamada_ventas: '525588544194',
    num_llamada_compras: '525588544317',
    num_whatsapp_ventas: '525541704152',
    num_whatsapp_compras: '525593020642',
    created_at: '2022-07-25T08:50:28.184-05:00',
    updated_at: '2022-07-25T08:50:28.184-05:00',
  },
  {
    id: 5,
    fuente: 'Fbook  campaña',
    utm_source: 'facebook',
    utm_medium: 'ctw',
    num_llamada_ventas: '525588544170',
    num_llamada_compras: '525588544319',
    num_whatsapp_ventas: '525592252606',
    num_whatsapp_compras: '525593020737',
    created_at: '2022-07-25T08:53:13.360-05:00',
    updated_at: '2022-07-25T08:53:13.360-05:00',
  },
  {
    id: 6,
    fuente: 'Youtube',
    utm_source: 'youtube',
    utm_medium: 'description',
    num_llamada_ventas: '525588544176',
    num_llamada_compras: '525588544334',
    num_whatsapp_ventas: '525593020728  ',
    num_whatsapp_compras: '525593020749',
    created_at: '2022-07-25T08:54:18.079-05:00',
    updated_at: '2022-07-25T08:54:18.079-05:00',
  },
  {
    id: 7,
    fuente: 'Mercado Libre',
    utm_source: '',
    utm_medium: '',
    num_llamada_ventas: '525588544177',
    num_llamada_compras: '',
    num_whatsapp_ventas: '525570055048',
    num_whatsapp_compras: '',
    created_at: '2022-07-25T08:55:16.934-05:00',
    updated_at: '2022-07-25T08:55:16.934-05:00',
  },
  {
    id: 8,
    fuente: 'SoloAutos',
    utm_source: '',
    utm_medium: '',
    num_llamada_ventas: '525588544128',
    num_llamada_compras: '',
    num_whatsapp_ventas: '525593020216',
    num_whatsapp_compras: '',
    created_at: '2022-07-25T08:55:52.624-05:00',
    updated_at: '2022-07-25T08:56:05.901-05:00',
  },
  {
    id: 9,
    fuente: 'Autocosmos',
    utm_source: '',
    utm_medium: '',
    num_llamada_ventas: '  525588544129',
    num_llamada_compras: '',
    num_whatsapp_ventas: '525568268752',
    num_whatsapp_compras: '',
    created_at: '2022-07-25T08:56:40.859-05:00',
    updated_at: '2022-07-25T08:58:05.681-05:00',
  },
  {
    id: 10,
    fuente: 'Seminuevos.com',
    utm_source: '',
    utm_medium: '',
    num_llamada_ventas: '525588544133',
    num_llamada_compras: '',
    num_whatsapp_ventas: '525593020633',
    num_whatsapp_compras: '',
    created_at: '2022-07-25T08:57:23.087-05:00',
    updated_at: '2022-07-25T08:57:23.087-05:00',
  },
]

export const vdpsWith404 = [5748, 5549, 6051, 6224]

// ONLY This vehicles id redirect to SRP
export const idsToRedirectSRP = [
  8044, 9832, 7685, 7759, 8417, 8398, 8484, 9838, 8112, 9840, 8044, 8331, 7975,
]

export const branches = [
  {
    name: 'SYC_NAPOLES_NAME',
    address: {
      text: 'SYC_NAPOLES_ADDRESS',
      link: 'https://www.google.com.mx/maps/place/ClikAuto+N%C3%A1poles/@19.3862948,-99.1786307,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff3284cfe257:0xafeaaa93249c3baf!8m2!3d19.3862948!4d-99.1760558',
    },
  },
  {
    name: 'SYC_SATELITE_NAME',
    address: {
      text: 'SYC_SATELITE_ADDRESS',
      link: 'https://www.google.com.mx/maps/place/ClikAuto+Sat%C3%A9lite/@19.5278045,-99.226764,17z/data=!3m1!4b1!4m5!3m4!1s0x85d203864bbbc373:0x2a8c27827014eaec!8m2!3d19.5278045!4d-99.2241891',
    },
  },
]

export const landingBranches = [
  {
    stateName: 'Estado de México',
    branches: [
      {
        branchName: 'ClikAuto Lomas Verdes',
        address: {
          text: 'LOMAS_VERDES_ADDRESS',
          link: 'https://www.google.com/maps/place/ClikAuto+Lomas+Verdes/@19.5070175,-99.2602396,17z/data=!3m1!4b1!4m6!3m5!1s0x85d2030436afc28d:0xc16ef7f3a8c95074!8m2!3d19.5070175!4d-99.2602396!16s%2Fg%2F11vbg9j907?entry=ttu',
          latitude: 19.5070175,
          longitude: -99.2602396,
        },
        schedule: {
          week: '9am a 7pm.',
          saturday: '9am a 5pm.',
          sunday: '10am a 4pm.',
          disclaimer:
            '*Horarios pueden ser variados en días feriados, consulta con un asesor para confirmar.',
        },
        whatsapp: whatsappVTA,
        phone_number: 5589571916,
        srp_path: '/autos-seminuevos/branch_id-32',
        srp_path_staging: '/autos-seminuevos/branch_id-12',
        photos: [
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/lomas-verdes/ClikAuto_Lomas_Verdes_Entrada.webp',
            title: 'Fachada Sucursal ClikAuto Lomas Verdes. ',
            alt: 'Fachada Sucursal ClikAuto Lomas Verdes. ',
          },
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/lomas-verdes/ClikAuto_Lomas_Verdes_Autos.webp',
            title: 'Interior Sucursal ClikAuto Lomas Verdes. ',
            alt: 'Interior Sucursal ClikAuto Lomas Verdes. ',
          },
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/lomas-verdes/ClikAuto_Lomas_Verdes_Autos_sala.webp',
            title: 'Interior Sucursal ClikAuto Lomas Verdes. ',
            alt: 'Interior Sucursal ClikAuto Lomas Verdes. ',
          },
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/lomas-verdes/ClikAuto_Lomas_Verdes_Entrada_lateral.webp',
            title: 'Fachada Sucursal ClikAuto Lomas Verdes. ',
            alt: 'Fachada Sucursal ClikAuto Lomas Verdes. ',
          },
        ],
      },
    ],
  },
  {
    stateName: 'Ciudad de Querétaro',
    branches: [
      {
        branchName: 'ClikAuto La Capilla',
        address: {
          text: 'SYC_LA_CAPILLA_ADDRESS',
          link: 'https://maps.app.goo.gl/KPVSYjnZragetUoz7',
          latitude: 20.5769193,
          longitude: -100.4123574,
        },
        schedule: {
          week: '9am a 8pm.',
          saturday: '9am a 6pm.',
          sunday: '10am a 6pm.',
          disclaimer:
            '*Horarios pueden ser variados en días feriados, consulta con un asesor para confirmar.',
        },
        whatsapp: whatsQueretaro,
        phone_number: phoneQueretaro,
        srp_path: '/autos-seminuevos/branch_id-36',
        srp_path_staging: '/autos-seminuevos/branch_id-14',
        photos: [
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/queretaro/ClikAuto_Queretaro_estacionamiento_volado.webp',
            title: 'Fachada Sucursal ClikAuto La Capilla. ',
            alt: 'Fachada Sucursal ClikAuto La Capilla. ',
          },
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/queretaro/ClikAuto_Queretaro_estacionamiento_perfil.webp',
            title: 'Interior Sucursal ClikAuto La Capilla. ',
            alt: 'Interior Sucursal ClikAuto La Capilla. ',
          },
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/queretaro/ClikAuto_Queretaro_estacionamiento_frente.webp',
            title: 'Interior Sucursal ClikAuto La Capilla. ',
            alt: 'Interior Sucursal ClikAuto La Capilla. ',
          },
          {
            url: 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/queretaro/ClikAuto_Queretaro_entrada.webp',
            title: 'Fachada Sucursal ClikAuto La Capilla. ',
            alt: 'Fachada Sucursal ClikAuto La Capilla. ',
          },
        ],
      },
    ],
  },
]

export const expedientSteps = [
  {
    title: 'Datos',
    value: 1,
  },
  {
    title: 'Documentos',
    value: 2,
  },
  {
    title: 'Facturación',
    value: 3,
  },
]

export const professionList = [
  'Empleado',
  'Comerciante',
  'Ama de casa',
  'Estudiante',
  'Persona independiente',
  'Otro',
]
export const taxRegimeList = [
  'Persona física',
  'Persona física con actividad empr.',
  'Persona moral',
]
export const deductVATList = ['Si', 'No']
export const useCFDIList = [
  'Adquisición de mercancías',
  'Gastos en general',
  'Equipo de transporte',
  'Otra maquinaria y equipo',
  'Donativos',
  'Gastos en transportación escolar obligatoria',
  'Por definir',
]
export const sourceResourcesList = [
  'Honorarios',
  'Ahorro',
  'Arrendamiento',
  'Sueldos y salarios',
  'Patrimonio',
  'Herencia',
  'Negocio propio o actividad profesional',
  'Otro',
]
export const allowedFormatToUploadImage = 'image/jpeg,image/gif,image/png,application/pdf,image/x-eps'
export const allowedFormatsDigExp = 'image/jpeg,image/gif,image/png,image/x-eps,image/webp'
export const allowedFormatsInvoiceDigExp = 'image/jpeg,image/gif,image/png,image/x-eps,image/webp, application/pdf'
export const easyWhatsappMessages = {
  default: 'Hola, quiero que me ayuden a vender mi auto con EASY.',
  facebookAds:
    'Hola, vi su anuncio en Facebook y quiero que me ayuden a vender mi auto con EASY.',
  facebook:
    'Hola, vi su página en Facebook y quiero que me ayuden a vender mi auto con EASY.',
  mailing:
    '¡Hola!  Recibí un correo y quiero que me ayuden a vender mi auto con EASY, me das más información',
  tiktok:
    'Hola, vi su anuncio en TikTok y quiero que me ayuden a vender mi auto con EASY.',
}

export const mapsLocations = [
  'https://maps.app.goo.gl/VUqXFeJDgzfK4pkD8', // napoles
  'https://maps.app.goo.gl/MLTfyTsr6qve6pev9', // satelite
  'https://maps.app.goo.gl/UjAk8XFazHGg8par9', // gran sur
  'https://maps.app.goo.gl/3w8if6WDwZTwu2mn7', // lomas verdes
  'https://maps.app.goo.gl/i6gK2cNm2YwKPaLN7', // juriquilla
  'https://maps.app.goo.gl/KPVSYjnZragetUoz7', // la capilla
]

export const b2bVehicles = [
  11992, 19820, 20850, 19455, 17258, 16833, 22046, 17546, 17171, 20568, 865,
  12107, 17188, 13117, 21859, 6808, 15434, 17173, 16911, 15874, 13821, 16205,
  20457, 13909, 12373, 16906, 12924, 12540, 48810, 1913, 24174, 23853, 24159,
  23674, 23658, 23827, 24189, 48892,
  // 48810 only for staging]
]

export const minPrice = 100000 // 50000
export const maxPrice = 1000000 // 2050000

export const easyReport = {
  customer: {
    userId: null,
    name: 'Sell Your Car Test',
    phone: '521234567890',
    email: 'test@sell-your-car.com',
  },
  vehicle: {
    year: '2020',
    make: 'CHEVROLET',
    model: 'BEAT',
    version: 'ACTIV, TM, 5 PTS., ABS, AAC, 5 pas., 1.2L, 81 HP',
    mileage: '30,000',
    condition: 'good',
    invoices: 'Sí, es 1 factura',
    theftReport: 'No',
    recovered: 'No',
    registration: 'Si',
  },
  scheduling: {
    date: '2024/03/02',
    time: '10:00:00',
    branch: {
      company_id: 4,
      branch_id: 12,
      name: 'EDO MEX Lomas Verdes',
      photo_url:
        'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/ClikAuto_Lomas_Verdes.png',
      branch_type: 'externa',
      review_minutes_duration: 60,
      attention_same_day: false,
      display_order: 3,
      address: 'Avenida Lomas Verdes 545, EDO. MEX Naucalpan de Juárez',
      branch_picture_url:
        'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/ClikAuto_Lomas_Verdes.png',
      latitude: 19.3038025,
      longitude: -99.1660036,
      address_json: {
        zip_code: '53120',
        latitude: 19.3038025,
        longitude: -99.1660036,
        country_name: 'Mexico',
        state_name: 'EDO. MEX',
        city_name: 'Naucalpan de Juárez',
        neighborhood: 'Naucalpan de Juárez',
        street_name: 'Avenida Lomas Verdes',
        external_number: '545',
        internal_number: '',
        formatted_address: '',
      },
    },
  },
  documents: [
    {
      ine_front: '',
      ine_back: '',
      circulation_card_front: '',
      circulation_card_back: '',
      invoice_front: '',
      invoice_back: '',
    },
  ],
}

export const isDirectSallingTypes = ['b2c', 'b2b']
export const b2bB2cOptions = ['b2b', 'b2b_b2c']

export const externalBranches = [
  {
    id: 40,
    agencia: 'HYUNDAI',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5510006300',
    enviroment: 'production',
  },
  {
    id: 38,
    agencia: 'TOYOTA',
    sucursal: 'REVOLUCION',
    whatsapp: '5586478000',
    enviroment: 'production',
  },
  {
    id: 39,
    agencia: 'TOYOTA',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5530003357',
    enviroment: 'production',
  },
  {
    id: 43,
    agencia: 'HONDA',
    sucursal: 'PEDREGAL',
    whatsapp: '5546300241',
    enviroment: 'production',
  },
  {
    id: 44,
    agencia: 'HONDA',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5510878872',
    enviroment: 'production',
  },
  {
    id: 41,
    agencia: 'ACURA',
    sucursal: 'PEDREGAL',
    whatsapp: '5546300242',
    enviroment: 'production',
  },
  {
    id: 42,
    agencia: 'ACURA',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5546300240',
    enviroment: 'production',
  },
  {
    id: 20,
    agencia: 'HYUNDAI',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5510006300',
    enviroment: 'staging',
  },
  {
    id: 18,
    agencia: 'TOYOTA',
    sucursal: 'REVOLUCION',
    whatsapp: '5586478000',
    enviroment: 'staging',
  },
  {
    id: 19,
    agencia: 'TOYOTA',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5530003357',
    enviroment: 'staging',
  },
  {
    id: 23,
    agencia: 'HONDA',
    sucursal: 'PEDREGAL',
    whatsapp: '5546300241',
    enviroment: 'staging',
  },
  {
    id: 24,
    agencia: 'HONDA',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5510878872',
    enviroment: 'staging',
  },
  {
    id: 21,
    agencia: 'ACURA',
    sucursal: 'PEDREGAL',
    whatsapp: '5546300242',
    enviroment: 'staging',
  },
  {
    id: 22,
    agencia: 'ACURA',
    sucursal: 'UNIVERSIDAD',
    whatsapp: '5546300240',
    enviroment: 'staging',
  },
]

export const bodiesType = [
  {
    position: 2,
    name: 'Sedan 4 puertas',
    slug: 'sedan-4-puertas',
    icon: 'sedan',
    count: 94,
  },
  {
    position: 1,
    name: 'Camioneta SUV',
    slug: 'camioneta-suv',
    icon: 'suv',
    count: 194,
  },
  {
    position: 3,
    name: 'Hatchback',
    slug: 'hatchback',
    icon: 'hatchback',
    count: 49,
  },
  {
    position: 5,
    name: 'Crossover',
    slug: 'crossover',
    icon: 'crossover',
    count: 7,
  },
  {
    position: 6,
    name: 'Coupe 2 puertas',
    slug: 'coupe-2-puertas',
    icon: 'coupe',
    count: 3,
  },
  {
    position: 7,
    name: 'Pickup',
    slug: 'pickup',
    icon: 'pickup',
    count: 13,
  },
  {
    position: 4,
    name: 'Van',
    slug: 'van',
    icon: 'van',
    count: 4,
  },
]

export const validAdvancedFilterKeys = [
  'maker',
  'model',
  'year',
  'transmission',
]
