import { darken } from '@mui/material/styles'

const styles = (theme) => ({
  a: {
    textDecoration: 'none',
  },
  '.wrapper-stycky-header': {
    position: 'relative',
    zIndex: '1100',
  },
  '.card-gray-border': {
    border: `1px solid ${theme.palette.background.border}`,
    padding: theme.spacing(2),
    borderRadius: '4px',
  },
  '.bg-grey': {
    backgroundColor: '#ECF0F1',
    '#root-container': {
      minHeight: '100dvh', // ' 'calc(100vh - 64px)',
      position: 'relative',
      '#main-content-container': {
        minHeight: 'calc(100vh - 80px)',
        // height: { xs: 'calc(100dvh - 80px)', lg: 'auto!important' },
        paddingX: 2,
        paddingBottom: 100,
        [theme.breakpoints.down('lg')]: {
          '&.isSchedule': {
            height: 'calc(100dvh - 80px)',
          },

        },
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 0,
          '&.dig-exp': {
            minHeight: '100dvh!',
            height: 'auto!important',
          },
        },
      },
      '#main-easy-content-container': {
        paddingBottom: 151,
        [theme.breakpoints.down('sm')]: {
          // paddingBottom: 200,
          paddingBottom: 0,
        },
        [theme.breakpoints.up('sm')]: {
          paddingBottom: 100,
        },
      },
    },
  },

  '.pb-0': {
    paddingBottom: '0 !important',
  },

  'html, body': {
    scrollBehavior: 'smooth',
    minHeight: '100dvh',
    margin: 0,
    padding: 0,
    // backgroundColor: theme.palette.background.default,
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
  },

  '.PrivatePickersToolbar-dateTitleContainer': {
    display: 'none!important',
  },

  '#root-container > div > .sticky:first-of-type': {
    zIndex: '1001!important',
  },

  '.sticky': {
    zIndex: 1000,
  },

  '.swiper': {
    width: '100%',
    height: '100%',

    '& div[class^="swiper-button-"]': {
      color: theme.palette.common.white,
    },

    '& .swiper-pagination': {
      bottom: 0,
    },

    '& .swiper-pagination-bullet': {
      backgroundColor: `${theme.palette.background.grey400}!important`,
      opacity: '1!important',
    },

    '& .swiper-pagination-bullet-active': {
      backgroundColor: `${theme.palette.primary.main}!important`,
    },

    '& .swiper-button-next::after, .swiper-button-prev::after': {
      color: theme.palette.primary.main,
      background: '#EFEFEF',
      borderRadius: '50%',
      padding: '10px 13px',
      fontSize: '12px',
      fontWeight: 'bold',
      border: `4px solid ${theme.palette.text.white}!important`,
    },

    '& .swiper-button-disabled': {
      '& *': {
        opacity: '0!important',
      },
    },

    '& .swiper-slide': {
      borderRadius: 5,
      boxSizing: 'border-box',
    },

    '& .swiper-button-prev:focus, .swiper-button-next:focus': {
      WebkitTapHighlightColor: 'transparent',
      outline: 'none',
      touchAction: 'manipulation',
    },
  },

  '&.touched-appraisal': {
    backgroundColor: '#F2F7FE !important',
  },

  '.MuiSelect-outlined.Mui-style': {
    '.MuiInputLabel-root': {
      color: `${theme.palette.text.grey300}!important`,
      fontSize: 14,
      fontWeight: '600!important',
    },
  },

  '& .MuiInputBase-root': {
    height: '40px!important',
    color: theme.palette.text.secondaryDark,
  },

  '& .MuiOutlinedInput-root': {
    height: '40px!important',
    color: theme.palette.text.secondaryDark,
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.down('sm')]: {
      height: '48px!important',
    },
  },

  '& .MuiAutocomplete-root': {
    '& .Mui-focused,:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.third.light}!important`,
      },
    },

  },

  '.MuiTextField-root.Mui-style': {
    marginBottom: theme.spacing(1),

    // default
    // input default
    '& .MuiOutlinedInput-input': {
      color: `${theme.palette.text.primary}!important`,
      fontWeight: 500,
    },
    // label default
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
      color: `${theme.palette.text.primary}`,
    },

    // filled
    '& input:not(:placeholder-shown) + fieldset': {
      border: `1px solid ${theme.palette.text.primary}!important`,
      boxShadow: 'none',
      color: `${theme.palette.text.primary}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.primary}!important`,
    },

    // focuesed
    '& .Mui-focused': {
      // '& input + fieldset': {
      //   border: `1px solid ${theme.palette.third.light}!important`,
      // },
      // new
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.third.light}!important`,
      },
      '&.MuiInputLabel-root': {
        color: `${theme.palette.third.light}!important`,
      },
    },

    // error
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.main}!important`,
      },
      '&.MuiInputLabel-root': {
        color: `${theme.palette.error.main}!important`,
      },
    },

  },

  '.MuiTextField-root:not(.Mui-style)': {
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(1),
    },
    // label default
    '& .MuiOutlinedInput-input': {
      color: `${theme.palette.text.primary}!important`,
    },
    // filled
    '& input:not(:placeholder-shown) + fieldset': {
      border: `1px solid ${theme.palette.third.light}!important`,
      boxShadow: 'none',
    },
    // focus
    '& .Mui-focused': {
      '& input + fieldset': {
        border: `1px solid ${theme.palette.third.light}!important`,
      },
      // new
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.third.light}!important`,
      },
      '&.MuiInputLabel-root': {
        color: `${theme.palette.third.light}!important`,
      },
    },
    // error
    '& .Mui-error': {
      '& input + fieldset': {
        border: `1px solid ${theme.palette.error.main}!important`,
      },
    },
  },

  '.MuiFormControl-root': {
    backgroundColor: 'transparent!important',
    // label default
    '& .MuiFormLabel-root': {
      fontSize: 14,
      color: `${theme.palette.text.primary}`,
      transform: 'translate(14px, 11px) scale(1)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translate(14px, 15px) scale(1)',
      },
    },
    // select
    '& .MuiSelect-select': {
      paddingY: '0px',
    },
    // label small
    '& .MuiInputLabel-sizeSmall': {
      fontWeight: 500,
    },
    // label subtitle1
    '& .MuiTypography-root.MuiTypography-subtitle1': {
      fontWeight: 500,
    },
    // focused
    '& .Mui-focused': {
      color: `${theme.palette.third.light}`,
    },
    // label shrink
    '& .MuiInputLabel-shrink': {
      transform: 'translate(16px, 0px) scale(0.75)',
      backgroundColor: '#fff',
      color: `${theme.palette.third.light}`,
      fontWeight: 500,
    },
  },

  '.MuiTypography-root': {
    '&.MuiTypography-h1': {
      fontSize: '26px',
      lineHeight: '28px',
      letterSpacing: 'normal',
      fontWeight: '800',
      color: theme.palette.primary.main,
    },
    '&.MuiTypography-h2': {
      fontSize: '22px',
      lineHeight: '24px',
      fontWeight: '700',
      color: theme.palette.text.primary,
    },
    '&.MuiTypography-h3': {
      fontSize: '20px',
      lineHeight: '22px',
      fontWeight: '600',
      color: theme.palette.text.primary,
    },
    '&.MuiTypography-h4': {
      fontSize: '18px',
      lineHeight: '20px',
      fontWeight: '600',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-subtitle1': {
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: '600',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-subtitle2': {
      fontSize: '15px',
      lineHeight: '17px',
      fontWeight: '600',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-body1': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '500',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-body2': {
      fontSize: '13px',
      lineHeight: '15px',
      fontWeight: '400',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-strong': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '600',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-caption': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '400',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-quote': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '400',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-button-text': {
      fontSize: '14px',
      lineHeight: 'normal',
      fontWeight: '600',
      color: `${theme.palette.text.primary}`,
    },
    '&.MuiTypography-link-text': {
      fontSize: '12px',
      lineHeight: '12px',
      fontWeight: '600',
      color: `${theme.palette.text.primary}`,
      textDecoration: 'underline',
      textDecorationColor: `${theme.palette.text.black}!important`,
      cursor: 'pointer',
    },
  },

  '.MuiButtonBase-root': {
    textTransform: 'none!important',
    fontSize: '14px',
    lineHeight: '21px!important',
    fontWeight: '600!important',
    justifyContent: 'center!important',
    boxShadow: 'none!important',

    ':not(.MuiButton-easy)': {
      borderRadius: `${theme.shape.borderRadius * 8}px!important`,
    },

    '&.MuiButton-root': {
      [theme.breakpoints.down('sm')]: {
        '&:not(.MuiButton-text)': {
          height: '48px',
        },
      },

      '&.MuiButton-root.slim': {
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },
        minWidth: '200px',
        width: '100%',
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
      },

      '&.MuiButton-containedPrimary': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
          backgroundColor: darken(theme.palette.secondary.main, 0.1),
        },
      },

      '&.MuiButton-primaryVariant': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
          backgroundColor: darken(theme.palette.secondaryVariant.dark, 0.1),
        },
      },

      '&.MuiButton-outlinedPrimary': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },
        backgroundColor: theme.palette.background.greyF6,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primaryDark,
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },

      '&.MuiButton-outlinedPrimaryVariant': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.secondary.main,
        },
      },

      '&.MuiButton-text': {
        color: theme.palette.text.primary,
      },

      '&.MuiButton-special': {
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },

        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        background: theme.palette.secondary.main,
        color: theme.palette.text.white,
        '&:hover': {
          background: darken(theme.palette.secondary.main, 0.1),
          borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        },
        '&.selected': {
        },
      },

      '&.MuiButton-secondary': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primaryDark,
        backgroundColor: theme.palette.background.greyF6,
        fontSize: '14px',
        lineHeight: 'normal',
        fontWeight: '600',
        '&:hover': {
          background: theme.palette.secondary.light,
          borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        },
        '&.selected': {
        },
      },

      '&.MuiButton-default': {
        padding: theme.spacing(1.5),
        borderRadius: `${theme.shape.borderRadius * 8}px`,
        color: theme.palette.text.primaryDark,
        fontSize: '14px',
        lineHeight: 'normal',
        fontWeight: '600',
        '&.selected': {
        },
      },

      '&.MuiButton-grey': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        minHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          height: '48px',
        },
        backgroundColor: theme.palette.background.greyF6,
        color: theme.palette.text.primaryDark,
        '&:hover': {
          backgroundColor: darken(theme.palette.background.greyF6, 0.1),
        },
        '&:disabled': {
          backgroundColor: `${theme.palette.background.greyF6}!important`,
          color: theme.palette.text.white,
        },
      },

      '&.MuiButton-hightlight': {
        borderRadius: `${theme.shape.borderRadius * 8}px!important`,
        background: theme.palette.highlight.main,
        color: theme.palette.text.white,
      },
      '&.Mui-disabled': {
        fontWeight: 500,
        backgroundColor: `${theme.palette.background.grey700}!important`,
        border: 'none!important',
        color: `${theme.palette.text.white}!important`,
      },
      '&.MuiButton-easy': {
        background: '#202BB0',
        height: '56px',
        color: theme.palette.text.white,
        '&:hover': {
          background: '#10B1D7',
          transform: 'scale(1.02)',
        },
      },
    },
  },

  '.MuiTabs-root': {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(6),
    padding: `${theme.spacing(0.5)}!important`,
    justifyContent: 'center',
    maxWidth: { xs: 'auto', md: '100%!important' },
    width: { xs: 'auto', md: '100%!important' },
    '& .MuiTab-root': {
      borderRadius: theme.spacing(4),
      textTransform: 'none',
      margin: theme.spacing(0, 1),
      color: theme.palette.primary.main,
      height: 40,
      minHeight: 'unset',
      fontSize: 14,
      fontWeight: 600,
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.secondary.contrastText}!important`,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTab-root': {
        minHeight: 'unset',
        fontSize: 14,
      },
    },
    '& .MuiTabs-scrollButtons': {
    },
    '&.easy': {
      backgroundColor: '#fff',
      border: '1px solid #F1F1F1!important',
      borderRadius: '8px!important',
      '& .MuiTab-root': {
        borderRadius: '4px!important',
        color: '#343434!important',
        height: '56px!important',
        margin: '0!important',
        padding: '0!important',
      },
      '& .Mui-selected': {
        backgroundColor: '#202BB0',
        color: `${theme.palette.secondary.contrastText}!important`,
      },
    },
  },

  '.MuiRating-root': {
    '& .MuiRating-iconFilled': {
      color: '#FFE81D',
    },

    '& .MuiRating-iconEmpty': {
      color: '#989898',
    },
  },

  '.TilePicker-root': {
    '& .MuiTypography-h2': {
      color: theme.palette.common.black,
    },
  },

  '.Tile-root': {
    backgroundColor: theme.palette.background.tile,
    height: 'auto!important',
    '&.button-secondary': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(0.5),

      '& .MuiTypography-quote': {
        color: theme.palette.text.primaryDark,
        lineHeight: '21px',
        fontWeight: '600',
        padding: theme.spacing(0.5, 1),
        fontSize: '14px',
      },
      '&:hover': {
        backgroundColor: '#B3E4EF',
      },
    },
    '&.Tile-selected': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.dense': {
      '& .Tile-text': {
        padding: theme.spacing(0.5, 1),
        fontSize: 11,
      },
      '& .Tile-icon': {
        padding: theme.spacing(0.5, 0, 0.5, 1),
        fontSize: 11,
      },
    },
  },
  '.Version-card-root': {
    backgroundColor: theme.palette.background.tile,
    '&:hover': {
      backgroundColor: theme.palette.background.highlight,
    },
  },
  '.MuiMenuItem-root': {
    textTransform: 'capitalize',
  },

  '& .MuiModal-root ': {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
  '.center-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.MuiAutocomplete-listbox': {
    '& .MuiAutocomplete-option:not(:last-of-type)': {
      borderBottom: '1px solid #ececec',
    },
  },
  '.MuiAccordionSummary-root': {
    minHeight: theme.spacing(6),
    '&.Mui-expanded': {
      minHeight: `${theme.spacing(6)}!important`,
    },
  },
  '& .MuiAccordionDetails-root .p0': {
    padding: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0px!important',
  },
  '& .MuiAccordion-root.withBorder': {
    border: '1px solid #E9E9E9',
    boxShadow: 'none!important',
  },

  '.Snackbar-root': {
    zIndex: '1200!important',
  },
  '.MuiDrawer-root': {
    zIndex: '2010!important',
  },

  '&.MuiAlert-outlined-yellow': {
    border: '2px solid #FFE577',
    backgroundColor: `${theme.palette.background.default}!important`,
    fontSize: '12px!important',
    lineHeight: '1.1!important',
    textAlign: 'center',
    fontWeight: '600!important',
    padding: `${theme.spacing(1)}!important`,
    '& .MuiAlert-message': {
      padding: 0,
      overflow: 'hidden',
    },
  },

  '&.MuiAlert-outlined-green': {
    border: '2px solid #09C7A6',
    backgroundColor: `${theme.palette.background.default}!important`,
    fontSize: '12px!important',
    lineHeight: '1.1!important',
    textAlign: 'center',
    fontWeight: '600!important',
    padding: `${theme.spacing(1)}!important`,
    '& .MuiAlert-message': {
      padding: 0,
      overflow: 'hidden',
    },
  },
  '.MuiDrawer-paper': {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '444px',
      margin: '0 auto',
    },
  },
  ' .MuiPopover-root.MuiMenu-root': {
    zIndex: 99999,
  },

})

export default styles
